import request from "./request.js";

const user= {
    //登录
    login(params) {
        return request({
            url: '/auth/login',
            method: 'post',
            data: params,
        })
    },
    //注册
    register(params) {
        return request({
            url: '/auth/register',
            method: 'post',
            data: params,
        })
    },
    //发送验证码
    sendCode(params) {
        return request({
            url: '/auth/send_regcode',
            method: 'post',
            data: params,
        })
    },
    //获取用户信息
    getUserInfo(params) {
        return request({
            url: '/auth/me',
            method: 'post',
            data: params,
        })
    },
    //找回密码
    getPasswd(params) {
        return request({
            url: '/auth/reset',
            method: 'post',
            data: params,
        })
    },
    //用户信息修改
    setUserInfo(params) {
        return request({
            url: '/hospital/edit',
            method: 'post',
            data: params,
        })
    },

}
export default user